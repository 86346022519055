import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { constantCase } from 'constant-case';
import FormWidget from '../../FormWidget';
import store from '../../../redux/store/configureStore';
import { initApp } from '../../../redux/actions/init.actions';
import UserService from '../../../services/user.service';
import * as PatientTypeService from '../../../services/patientType.service';
import * as ServiceTypeService from '../../../services/serviceType.service';
import * as ProviderService from '../../../services/provider.service';
import * as RequestVerificationTypesService from '../../../services/verificationTypes.service';
import CoordinationTypeService from '../../../services/coordinationType.service';
import { fetchUserFacilitiesSuccess } from '../../../redux/actions/user.actions';
import {
  fetchPatientTypesSuccess,
  fetchServiceTypesSuccess,
  preloadProviderCapabilities,
} from '../../../redux/actions/service.actions';
import CallTypeService from '../../../services/callTypeService';
import EmsFacilityTypeService from '../../../services/emsFacilityType.service';
import RequestCancelReasonTypeService from '../../../services/requestCancelReasonTypes.service';
import PayerTypeService from '../../../services/payerType.service';
import OrdersService from '../../../services/orders/orders.service';
import AmbCircularProgress from '../../common/AmbCircularProgress';
import RequestTypeService from '../../../services/requestType.service';
import { theme } from '../../../theme';

const Version = styled.p`
  font-weight: 400;
  font-size: 12px;
  opacity: 0.5;
  background-color: inherit;
  margin: 0;
  padding: 8px 0 8px 0;
`;

const Container = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
`;

async function createEnum(fn, nameProp = 'name', idProp = 'id') {
  const array = await fn();

  return array.reduce((acc, element) => {
    acc[constantCase(element[nameProp])] = element[idProp];

    return acc;
  }, {});
}

async function loadTypeTables(user) {
  const [
    callTypes,
    transportReasons,
    coordinationTypes,
    emsFacilityTypes,
    cancelReasonTypes,
    payerTypes,
    requestTypes,
    requestVerificationTypes,
  ] = await Promise.all([
    createEnum(async () => CallTypeService.get(user.id)),
    OrdersService.getTransportReasons(true, true),
    createEnum(CoordinationTypeService.getCoordinationTypes),
    createEnum(EmsFacilityTypeService.getEmsFacilityTypes),
    createEnum(RequestCancelReasonTypeService.fetchCancelReasonTypes),
    createEnum(PayerTypeService.fetchPayerTypes),
    createEnum(RequestTypeService.fetchRequestTypes),
    createEnum(RequestVerificationTypesService.getRequestVerificationTypes),
  ]);

  Object.assign(window.ambulnzConfig, {
    callTypes,
    transportReasons,
    coordinationTypes,
    emsFacilityTypes,
    cancelReasonTypes,
    payerTypes,
    requestTypes,
    requestVerificationTypes,
  });
}

const SideContent = () => {
  const [sideContentLoadedState, setSideContentLoadedState] = useState(false);

  const loadAppState = async () => {
    store.dispatch(initApp());
    const account = store.getState().userAccount.selectedUserAccount;
    const user = store.getState().user.model;
    await loadTypeTables(account);

    const userFacilities = await UserService.getUserFacilities(user.id);
    store.dispatch(fetchUserFacilitiesSuccess(userFacilities));

    const [providerCapabilityResult, patientTypeResult, serviceTypeResult] = await Promise.all([
      ProviderService.getProviders(),
      PatientTypeService.getPatientTypes(),
      ServiceTypeService.getServiceTypes(),
    ]);
    store.dispatch(fetchPatientTypesSuccess(patientTypeResult));
    store.dispatch(fetchServiceTypesSuccess(serviceTypeResult));
    store.dispatch(preloadProviderCapabilities(providerCapabilityResult));
  };

  const removeSpinner = () => {
    const spinner = document.getElementById('spinner-div');
    // IE11 way to remove element from DOM
    spinner && spinner.parentNode.removeChild(spinner);
  };

  useEffect(() => {
    if (!sideContentLoadedState) {
      removeSpinner();
      loadAppState().then(() => setSideContentLoadedState(true));
    }
  }, [sideContentLoadedState]);

  const formContent = () => {
    if (sideContentLoadedState) {
      return <FormWidget />;
    }

    return (
      <Container>
        <AmbCircularProgress
          propsStyles={{
            position: 'absolute',
            top: 250,
            left: 240,
            zIndex: 1,
          }}
        />
      </Container>
    );
  };

  return (
    <div style={{ height: '100%', position: 'relative', paddingTop: 1, width: 'auto' }}>
      <div style={{ backgroundColor: theme.palette.divider, paddingLeft: 10, paddingRight: 10 }}>
        {formContent()}
        <Version>v{process.env.REACT_APP_VERSION}</Version>
      </div>
    </div>
  );
};

export default SideContent;
