import React from 'react';
import PropTypes from 'prop-types';
import AmbGrid from 'components/common/AmbGrid';
import SuggestionsInput from '../../common/SuggestionsInput';
import AmbTooltip from 'components/common/AmbTooltip';
import AmbCheckbox from '../../common/AmbCheckbox';
import AmbDropdown from '../../common/AmbDropdown';
import AmbDropdownTimePicker from '../../common/AmbDropdownTimePicker/AmbDropdownTimePicker';
import times from './pickupDestinationTimes';
import RoomNumber from '../RoomNumber';
import Flag from 'components/common/icons/Flag';
import DangerFlag from 'components/common/icons/DangerFlag';
import styled from '@emotion/styled';

const Location = ({
  autoFocus,
  department,
  departmentOptions,
  departmentOptionsDisabled,
  disabled,
  error,
  estTimePlaceholder,
  handleDepartmentChange,
  handleLocationFlagSet,
  handleLocationFlagUnset,
  helperText,
  inputId,
  inputPlaceholder,
  inputValue,
  isDispatcher,
  isResidence,
  isResidenceDisabled,
  items,
  loading,
  onInputClick,
  onInputValueChange,
  onSelectWaitTime,
  propsStyles,
  residenceHandleChange,
  roomFieldName,
  selectedItem,
  showEstTime,
  subLabel,
  suggestionFieldName,
  waitTimeValue,
  isDestinationConfirmed,
  destinationConfirmationRequired,
  destinationConfirmHandleChange,
  isFlagged,
  flagReason,
}) => {
  const isLocationSelected = selectedItem && Object.keys(selectedItem).length > 0;
  const DivNeededForTooltip = styled.div``;
  return (
    <>
      <AmbGrid>
        {isDispatcher &&
          (isFlagged ? (
            <AmbGrid
              style={{
                marginTop: 45,
                marginRight: 15,
                justifyContent: 'space-between',
                width: 'calc(100% - 15px)',
              }}
            >
              <div
                style={{
                  color: '#F2404B',
                  maxWidth: 'calc(100% - 40px)',
                  overflowWrap: 'break-word',
                  whiteSpace: 'normal',
                }}
              >
                The location is flagged as high risk. Reason: {flagReason}
              </div>
              <DangerFlag style={{ marginRight: 10 }} onClick={handleLocationFlagUnset} />
            </AmbGrid>
          ) : (
            <>
              <AmbTooltip
                aria-label={`unset-${inputId}-flag-tooltip`}
                name={`unset-${inputId}-flag-tooltip`}
                title="Flag location as high risk"
                placement="top-end"
              >
                <AmbGrid
                  style={{
                    marginTop: 45,
                    marginRight: 15,
                    justifyContent: 'flex-end',
                    width: 'calc(100% - 15px)',
                  }}
                >
                  <DivNeededForTooltip>
                    <Flag disabled={!isLocationSelected} onClick={handleLocationFlagSet} style={{ marginRight: 10 }} />
                  </DivNeededForTooltip>
                </AmbGrid>
              </AmbTooltip>
            </>
          ))}
      </AmbGrid>
      <AmbGrid>
        <AmbGrid style={{ marginTop: 10, flexBasis: '77%', position: 'relative' }}>
          <SuggestionsInput
            inputValue={inputValue}
            selectedItem={selectedItem}
            helperText={helperText}
            onInputValueChange={onInputValueChange}
            onInputClick={onInputClick}
            items={items}
            inputId={inputId}
            inputPlaceholder={inputPlaceholder}
            autoFocus={autoFocus}
            autoComplete="new-password"
            required
            loading={loading}
            name={suggestionFieldName}
            error={error}
            disabled={disabled || null}
            subLabel={subLabel}
          />
        </AmbGrid>
        <AmbGrid style={{ marginTop: 10, flexDirection: 'column', alignItems: 'flex-end' }}>
          <AmbCheckbox
            checked={isResidence}
            inputId={`${inputId}-residence-checkbox`}
            handleChange={residenceHandleChange}
            label={
              <>
                Residence
                <br />
                or Scene
              </>
            }
            propsStyles={{
              marginTop: 10,
              paddingLeft: 5,
              marginRight: 0,
            }}
            disabled={isResidenceDisabled || null}
          />
        </AmbGrid>
      </AmbGrid>
      {destinationConfirmationRequired && (
        <AmbCheckbox
          checked={isDestinationConfirmed}
          inputId={`${inputId}-destination-confirmed-checkbox`}
          handleChange={destinationConfirmHandleChange}
          label={
            <>
              I have verified the destination address with the receiving facility<span>&thinsp;*</span>
            </>
          }
          propsStyles={{
            marginLeft: '-10px',
          }}
        />
      )}
      <AmbGrid style={{ marginTop: 10, gap: '5px', justifyContent: 'space-between' }}>
        <div style={{ display: 'inline-flex', flex: '2' }}>
          <AmbDropdown
            placeholder="Department"
            options={departmentOptions.map(c => ({
              key: c.id,
              value: c.id,
              name: c.name,
            }))}
            inputId={`${inputId}-select-department`}
            containerStyles={{ width: '100%' }}
            onSelect={handleDepartmentChange}
            value={department || ''}
            disabled={disabled || departmentOptionsDisabled || null}
            selectInputStyles={{ minWidth: 145 }}
          >
            <option
              key="placeholder"
              value=""
              style={{
                color: '#919799',
              }}
            />
          </AmbDropdown>
        </div>
        <AmbGrid style={{ flex: 1, marginTop: 1 }}>
          <RoomNumber name={roomFieldName} inputId={`${inputId}-room-number`} />
        </AmbGrid>
        {showEstTime && (
          <AmbGrid style={{ marginTop: 7 }}>
            <AmbDropdownTimePicker
              propsStyles={propsStyles}
              times={times}
              placeholder={estTimePlaceholder}
              onSelect={onSelectWaitTime}
              value={waitTimeValue}
              disabled={disabled || null}
            />
          </AmbGrid>
        )}
      </AmbGrid>
    </>
  );
};

Location.propTypes = {
  inputValue: PropTypes.string.isRequired,
  selectedItem: PropTypes.shape().isRequired,
  helperText: PropTypes.string,
  onInputValueChange: PropTypes.func.isRequired,
  onInputClick: PropTypes.func.isRequired,
  inputId: PropTypes.string.isRequired,
  inputPlaceholder: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  suggestionFieldName: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  propsStyles: PropTypes.shape().isRequired,
  estTimePlaceholder: PropTypes.string.isRequired,
  onSelectWaitTime: PropTypes.func.isRequired,
  waitTimeValue: PropTypes.string.isRequired,
  roomFieldName: PropTypes.string.isRequired,
  isResidence: PropTypes.bool.isRequired,
  isResidenceDisabled: PropTypes.bool.isRequired,
  residenceHandleChange: PropTypes.func.isRequired,
  department: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
};

Location.defaultProps = {
  department: '',
  helperText: '',
  autoFocus: false,
  disabled: false,
};

export default Location;
